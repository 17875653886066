import {
	SyncOutlined,
	SaveOutlined,
	CheckCircleOutlined,
	FileTextOutlined,
	MessageOutlined,
	UploadOutlined,
	DeleteOutlined
} from '@ant-design/icons'
import {
	Button,
	Col,
	Row,
	Tooltip,
	Divider,
	Modal,
	Input,
	Form,
	Upload,
	message,
	Space,
	Popconfirm
} from 'antd'
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"
import './styles.scss'
import { createFile, updateReceiveValue } from './slice'
import { useDispatch } from 'react-redux'

const ExchangeActions = ({
	onReview,
	onFeedback,
	onFinalize,
	onDelete,
	onSendContract,
	onSendReceipt = () => { },
	receiptsAlreadySent,
	onSave,
	loading,
	action,
	files,
	cpf_cnpj,
	status,
	onApprove = () => { },
	type = "receivment",
	isBO = false,
	exchageId,
	exchange,
}) => {
	const [form] = Form.useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [exgFiles, setExgFiles] = useState(files)
	const [newFile, setNewFile] = useState(false)
	const [isUploading, setIsUploading] = useState(false)

	const dispatch = useDispatch()

	useEffect(() => {
		if (newFile) {
			dispatch(
				updateReceiveValue({
					receive_value_id: exchageId,
					files: exgFiles,
					spread: exchange.spread,
					taxes: exchange.taxes,
				})
			);
			setNewFile(false)
		}
	}, [newFile])

	const customRequest = ({ file, onProgress, onSuccess, onError }) => {
		setIsUploading(true)
		var formData = new FormData();
		formData.append("file", file);

		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				onProgress({ percent });
			},
		};

		cpf_cnpj = cpf_cnpj.replace(/\D/g, '');

		dispatch(
			createFile({
				formData: formData,
				config: config,
				cpf_cnpj: cpf_cnpj,
				onSuccess: (response) => {
					const newFile = {
						id: Number(response.data.file_id),
						name: file.name,
					};
					setExgFiles([newFile]);
					onSuccess("Ok");
					setNewFile(true)
					setIsUploading(false)
					message.success('Upload concluído com sucesso.');
				},
				onError: (err) => {
					onError({ err })
					message.error(err.message)

					setIsUploading(false)
				},
			})
		);
	};


	const handleOk = () => {
		setIsModalVisible(false);
		form.submit()
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		form.resetFields()
	};
	return <Col className='exchange-actions'>
		<Divider />
		<Row>
			{onSave && <Tooltip title={'Salvar'}>
				<Button
					loading={loading === "save"}
					className="update-register-btn"
					htmlType="submit"
					onClick={() => onSave()}
					icon={<SaveOutlined />}
				>Salvar alterações</Button>
			</Tooltip>}
			<Space>
				<Tooltip title={'Reprovar'}>
					<Button
						disabled={status !== "waiting_approval"}
						loading={loading === "feedback"}
						className="review-btn"
						onClick={() => setIsModalVisible(true)}
						icon={<MessageOutlined />}
					>Reprovar Operação</Button>
				</Tooltip>
				{isBO ? (
					<Tooltip title={'Aprovar'}>
						<Button
							disabled={status !== "waiting_approval"}
							loading={loading === "review"}
							className="review-btn"
							onClick={() => onApprove(isBO)}
							icon={<CheckCircleOutlined />}
						>Aprovar Documentação e Operação</Button>
					</Tooltip>
				) : (
					<>
						{type === "receivment" &&
							<>
								<Tooltip title={'Aprovar Documentação'}>
									<Button
										disabled={status !== "waiting_approval"}
										loading={loading === "review"}
										className="review-btn"
										onClick={() => onApprove()}
										icon={<CheckCircleOutlined />}
									>Aprovar Documentação</Button>
								</Tooltip>
							</>
						}
						<Tooltip title={'Aprovar'}>
							<Button
								disabled={type === "payment" ? status !== "waiting_approval" : status !== "docs_approved"}
								loading={loading === "review"}
								className="review-btn"
								onClick={() => onReview()}
								icon={<CheckCircleOutlined />}
							>Aprovar Operação</Button>
						</Tooltip>
					</>
				)
				}
				{!isBO}
				<Upload
					disabled={status !== "created_by_bo"}
					showUploadList={false}
					accept=".pdf, .png, .jpg, .jpeg"
					multiple={true}
					beforeUpload={(file) => {
						const isLt20M = file.size / 1024 / 1024 < 20;
						if (!isLt20M) {
							message.error("O Arquivo precisa ser menor que 20Mb!", 5);
							return false;
						}
						return true;
					}}
					customRequest={
						customRequest
					}
				>
					<Tooltip title={'Enviar Documentação'}>
						<Button
							disabled={status !== "created_by_bo"}
							className="update-register-btn"
							icon={<UploadOutlined />}
							loading={isUploading}
						>{isUploading ? "Enviando Documentação" : "Enviar Documentação"}</Button>
					</Tooltip>
				</Upload>
				<Upload
					disabled={status !== "waiting_contract"}
					showUploadList={false}
					accept=".pdf, .png, .jpg, .jpeg"
					multiple={true}
					beforeUpload={(file) => {
						try {
							if (file) {
								onSendContract(file)
								return true
							}
						} catch (err) { }

						message.error("Arquivo inválido")
						return Upload.LIST_IGNORE
					}}
				>
					<Tooltip title={'Enviar Contrato'}>
						<Button
							disabled={status !== "waiting_contract"}
							loading={loading === "contract"}
							className="update-register-btn"
							icon={<UploadOutlined />}
						>Enviar Contrato</Button>
					</Tooltip>
				</Upload>

				{type === "payment" && (
					<Tooltip title={'Enviar Recibo'}>
						<Button
							disabled={receiptsAlreadySent ||
								!["waiting_deposit_receipt", "waiting_sign_doc", "waiting_contract"].includes(status)
							}
							className="update-register-btn"
							loading={loading === "receipts"}
							onClick={() => onSendReceipt()}
							icon={<UploadOutlined />}
						>Enviar Recibo
						</Button>
					</Tooltip>
				)}

				<Tooltip title={'Finalizar Operação'}>
					<Button
						loading={loading === "finalize"}
						disabled={status !== "completed" && status !== "waiting_contract"}
						className="update-register-btn"
						onClick={() => onFinalize()}
						icon={<FileTextOutlined />}
					>Finalizar Operação</Button>
				</Tooltip>

				<Tooltip title={'Excluir Operação'}>
					<Popconfirm
						title="Deseja mesmo excluir esta operação?"
						placement="bottom"
						onConfirm={() => onDelete()}
						onCancel={'cancel'}
						okType='danger'
						okText="Excluir"
						cancelText="Cancelar"
					>
						<Button
							loading={loading === "finalize"}
							className="update-register-btn"
							icon={<DeleteOutlined />}
						>Excluir Operação</Button>
					</Popconfirm>
				</Tooltip>
			</Space>
		</Row>
		<Divider />
		<Modal className='feedback-modal' title="Feedback" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
			<Form
				form={form}
				onFinish={(values) => onFeedback(values)}
			>
				<Form.Item name="text" initialValue="">
					<Input.TextArea />
				</Form.Item>
			</Form>
		</Modal>
	</Col >
}

ExchangeActions.propTypes = {
	onFeedback: PropTypes.func,
	onReview: PropTypes.func,
	onFinalize: PropTypes.func,
	onDelete: PropTypes.func,
	onSendContract: PropTypes.func,
	onSave: PropTypes.func,
	loading: PropTypes.string,
	action: PropTypes.string,
	status: PropTypes.string,
	type: PropTypes.string,
	isBO: PropTypes.bool,
	onSendReceipt: PropTypes.func,
	receiptsAlreadySent: PropTypes.bool,
	onApprove: PropTypes.func,
	files: PropTypes.array,
	cpf_cnpj: PropTypes.string,
	exchageId: PropTypes.number,
	exchange: PropTypes.object,
};

export default ExchangeActions