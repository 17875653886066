import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../../utils/status'
import {
	getDataPayment,
	boReview,
	contractUpload,
	downloadFile,
	finalizePayment,
	currencyRates,
	putPayment,
	postCatchPayment,
	sendReceipt,
	linkToSignedDoc,
	getExchangeRates,
	removePayment,
	downloadZip
} from './service'

const initialState = {
	currencyPrice: 0,
	status: {
		getPayment: stateStatus.idle,
		getPaymentBackground: stateStatus.idle,
		putPayment: stateStatus.idle,
		boReview: stateStatus.idle,
		refuse: stateStatus.idle,
		finalize: stateStatus.idle,
		uploadContract: stateStatus.idle,
		getCurrencyRates: stateStatus.idle,
		currencyRate: stateStatus.idle,
		catchPayment: stateStatus.idle,
		sendReceipt: stateStatus.idle,
		downloadContract: stateStatus.idle,
		fileDownload: {},
		exchangeRates: stateStatus.idle,
	},
	payment: null,
	errorMsg: null
}

export const savePayment = createAsyncThunk(
	'recept/savePayment',
	async (data) => {
		const response = await putPayment(data)
		return response.data
	}
)

export const exchangeRates = createAsyncThunk(
	'recept/getExchangeRates',
	async (data) => {
		const response = await getExchangeRates(data.sendValueId, data.receiveValueId)
		return response.data
	}
)


export const getPayment = createAsyncThunk(
	'payment/getPayment',
	async ({ id }) => {
		const response = await getDataPayment({
			send_value_id: id,
		})
		return response.data
	}
)

export const getPaymentBackground = createAsyncThunk(
	'payment/getPaymentBackground',
	async ({ id }) => {
		const response = await getDataPayment({
			send_value_id: id,
		})
		return response.data
	}
)

export const catchPayment = createAsyncThunk(
	'payment/catchPayment',
	async (data) => {
		const response = await postCatchPayment(data)
		return response.data
	}
)

export const postBoReview = createAsyncThunk(
	'payment/boReview',
	async (data) => {
		const response = await boReview({
			send_value_id: data.send_value_id,
			action_type: "positive",
			text: "Operação aprovada.",
			nature_exchange: data.nature_exchange,
			spread: data.spread,
			taxes: data.taxes,
		})
		return response.data
	}
)

export const refuse = createAsyncThunk(
	'payment/refuse',
	async ({ id, need_changes }) => {
		const response = await boReview({
			send_value_id: id,
			action_type: "negative",
			text: need_changes,
		})
		return response.data
	}
)

export const fileDownload = createAsyncThunk(
	'payment/fileDownload',
	async ({ file }) => {
		const response = await downloadFile(file.id)
		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', file.file_name);
		document.body.appendChild(link);
		link.click();
		link.remove();
		return true
	}
)

function createBlob(data, contentType) {
	return new Blob([data], { type: contentType });
}

export const downloadZipFiles = createAsyncThunk(
	'payment/downloadZipFiles',
	async ({ sendValueId }, { rejectWithValue }) => {
		try {
			const response = await downloadZip(sendValueId)
			const zipBloob = createBlob(response.data, 'application/zip')
			const zipUrl = URL.createObjectURL(zipBloob)
			const link = document.createElement('a')
			link.href = zipUrl
			link.setAttribute('download', 'transaction_files.zip')
			document.body.appendChild(link)
			link.click()
			link.remove()

			return true
		} catch (error) {
			return rejectWithValue(error.message)
		}
	}
)

export const downloadContract = createAsyncThunk(
	'payment/downloadContract',
	async (fileId) => {
		const response = await linkToSignedDoc(fileId)
		window.open(response.data.signed_file_url || response.data.original_file_url, "_blank")
		return true
	}
)



export const uploadContract = createAsyncThunk(
	'payment/uploadContract',
	async ({ id, file }) => {
		const data = new FormData()
		data.append("file", file)
		data.append("send_value_id", id)

		const response = await contractUpload(data)
		return response.data
	}
)


export const sendReceiptBO = createAsyncThunk(
	'payment/uploadReceipt',
	async (data) => {
		const response = await sendReceipt(data)
		return response.data
	}
)

export const finalize = createAsyncThunk(
	'payment/finalize',
	async ({ id, comments }) => {
		const response = await finalizePayment({
			send_value_id: id,
			comments,
		})
		return response.data
	}
)

export const deletePayment = createAsyncThunk(
	'payment/deletePayment',
	async ({ id }) => {
		const response = await removePayment(id)
		return response.data
	}
)

export const getCurrencyRates = createAsyncThunk(
	'payment/currency_rates',
	async ({ id }) => {
		const response = await currencyRates({
			send_value_id: id,
		})
		return response.data.rate
	}
)



export const payment = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		resetStatus: (state) => {
			state.status.getPayment = stateStatus.idle
		},
		removeFile: (state, action) => {
			state.payment = {
				...state.payment,
				files: state.payment.files.filter(file => file.id !== action.payload),
			}
		},
		resetSendReceiptBOStatus: (state) => {
			state.status.sendReceipt = stateStatus.idle
			state.errorMsg = null
		}
	},
	extraReducers: {
		// Get payment by ID
		[getPayment.pending]: (state) => {
			state.status.getPayment = stateStatus.loading
		},
		[getPayment.fulfilled]: (state, action) => {
			state.status.getPayment = stateStatus.succeeded
			state.payment = action.payload
		},
		[getPayment.rejected]: (state, action) => {
			state.status.getPayment = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// getPaymentBackground
		[getPaymentBackground.pending]: (state) => {
			state.status.getPaymentBackground = stateStatus.loading
		},
		[getPaymentBackground.fulfilled]: (state, action) => {
			state.status.getPaymentBackground = stateStatus.succeeded
			state.payment = action.payload
		},
		[getPaymentBackground.rejected]: (state, action) => {
			state.status.getPaymentBackground = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Save Payment
		[savePayment.pending]: (state) => {
			state.status.savePayment = stateStatus.loading
		},
		[savePayment.fulfilled]: (state, action) => {
			state.status.savePayment = stateStatus.succeeded
			state.payment = action.payload
		},
		[savePayment.rejected]: (state, action) => {
			state.status.savePayment = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Catch Payment
		[catchPayment.pending]: (state) => {
			state.status.catchPayment = stateStatus.loading
		},
		[catchPayment.fulfilled]: (state, action) => {
			state.status.catchPayment = stateStatus.succeeded
			state.payment = action.payload
		},
		[catchPayment.rejected]: (state, action) => {
			state.status.catchPayment = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Review Payment
		[postBoReview.pending]: (state) => {
			state.status.boReview = stateStatus.loading
		},
		[postBoReview.fulfilled]: (state, action) => {
			state.status.boReview = stateStatus.succeeded
			state.payment = action.payload
		},
		[postBoReview.rejected]: (state, action) => {
			state.status.boReview = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Upload Contract
		[uploadContract.pending]: (state) => {
			state.status.uploadContract = stateStatus.loading
		},
		[uploadContract.fulfilled]: (state, action) => {
			state.status.uploadContract = stateStatus.succeeded
			state.payment = action.payload
		},
		[uploadContract.rejected]: (state, action) => {
			state.status.uploadContract = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// refuse payment
		[refuse.pending]: (state) => {
			state.status.refuse = stateStatus.loading
		},
		[refuse.fulfilled]: (state, action) => {
			state.status.refuse = stateStatus.succeeded
			state.payment = action.payload
		},
		[refuse.rejected]: (state, action) => {
			state.status.refuse = stateStatus.failed
		},
		// Finalize Payment
		[finalize.pending]: (state) => {
			state.status.finalize = stateStatus.loading
		},
		[finalize.fulfilled]: (state, action) => {
			state.status.finalize = stateStatus.succeeded
			state.payment = action.payload
		},
		[finalize.rejected]: (state, action) => {
			state.status.finalize = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// download file
		[fileDownload.pending]: (state, action) => {
			const { file } = action.meta.arg
			state.status.fileDownload[file.id] = stateStatus.loading
		},
		[fileDownload.fulfilled]: (state, action) => {
			const { file } = action.meta.arg
			state.status.fileDownload[file.id] = stateStatus.succeeded
		},
		[fileDownload.rejected]: (state, action) => {
			const { file } = action.meta.arg
			state.status.fileDownload[file.id] = stateStatus.failed
			state.errorMsg = `Erro ao fazer o download do arquivo ${file.file_name}`
		},
		// download contract
		[downloadContract.pending]: (state) => {
			state.status.downloadContract = stateStatus.loading
		},
		[downloadContract.fulfilled]: (state, action) => {
			state.status.downloadContract = stateStatus.succeeded
		},
		[downloadContract.rejected]: (state, action) => {
			state.status.downloadContract = stateStatus.failed
			state.errorMsg = "Erro ao gerar link de download"
		},
		// currency rates
		[getCurrencyRates.pending]: (state) => {
			state.status.getCurrencyRates = stateStatus.loading
		},
		[getCurrencyRates.fulfilled]: (state, action) => {
			state.status.getCurrencyRates = stateStatus.succeeded
			state.currencyRate = action.payload
		},
		[getCurrencyRates.rejected]: (state, action) => {
			state.status.getCurrencyRates = stateStatus.failed
		},
		// send receipt
		[sendReceiptBO.pending]: (state) => {
			state.status.sendReceipt = stateStatus.loading
		},
		[sendReceiptBO.fulfilled]: (state, action) => {
			state.status.sendReceipt = stateStatus.succeeded
			state.payment = action.payload
		},
		[sendReceiptBO.rejected]: (state, action) => {
			state.status.sendReceipt = stateStatus.failed
			state.errorMsg = action.error.message
		},

		// exchange rate

		[exchangeRates.pending]: (state) => {
			state.status.exchangeRates = stateStatus.loading
		},
		[exchangeRates.fulfilled]: (state, action) => {
			state.status.exchangeRates = stateStatus.succeeded
			state.currencyPrice = (action.payload.rate / 10000)
		},
		[exchangeRates.rejected]: (state, action) => {
			state.status.exchangeRates = stateStatus.failed
			state.errorMsg = action.error.message
		},
	}
})

export const { resetStatus, removeFile, resetSendReceiptBOStatus } = payment.actions;

export const selectPayment = (state) => state.payment
